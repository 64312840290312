import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import BasicList from "../components/BasicList"
import DotSection from "../components/DotSection"
import Hero from "../components/Hero"
import HeroLead from "../components/HeroLead"
import HeroTitle from "../components/HeroTitle"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import SubTitle from "../components/SubTitle"
import Text from "../components/Text"
import Title from "../components/Title"
import links from "../content/links"
import metaImg from "../images/luottokorttipuhelin.svg"

export default (): JSX.Element => (
  <Layout>
    <SEO
      title="Töitämme"
      description="Muutama poiminta töistämme, mitä olemme luoneet asiakkaillemme."
      pageImage={metaImg}
      pageUrl={links.works}
    />

    <Hero>
      <HeroTitle>
        <h1>Töitämme</h1>
      </HeroTitle>
      <HeroLead>Muutama poiminta töistämme</HeroLead>
    </Hero>
    <DotSection dotPosition="top-right">
      <div className="grid lg:grid-cols-2 lg:space-x-5">
        <div>
          <SubTitle tag="p">Kunnossapitojärjestelmä</SubTitle>
          <Title tag="h2">Mainiox</Title>
          <Text>
            Toteutimme Mainoixille SaaS ohjelmiston. MainioX on mobiili- ja
            web-käyttöinen kunnossapitojärjestelmä, minkä avulla pystyt luomaan
            ja tallentamaan tehdyt huollot, sekä aikatauluttamaan tulevat
            huollot
          </Text>
          <BasicList>
            <li>Alustat: Web, backend ja mobiili</li>
            <li>
              Palvelut: Suunnittelu, digitaalinen markkinointi ja
              ohjelmistokehitys
            </li>
          </BasicList>
        </div>
        <div className="mx-auto  max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 mt-6 lg:mt-0">
          <StaticImage alt="mainiox" src="../images/mainiox.svg" />
        </div>
      </div>
    </DotSection>
    <DotSection dotPosition="bottom-left">
      <div className="grid lg:grid-cols-2 lg:space-x-5">
        <div className="mx-auto  max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 mt-6 lg:mt-0">
          <StaticImage alt="qmclouds" src="../images/qmclouds.svg" />
        </div>
        <div>
          <SubTitle tag="p">Laadunhallintaa verkossa</SubTitle>
          <Title tag="h2">QMClouds</Title>
          <Text>
            QMCloudsille valmistimme SaaS palvelun. MainioX on mobiili- ja
            web-käyttöinen kunnossapitojärjestelmä, minkä avulla pystyt luomaan
            ja tallentamaan tehdyt huollot, sekä aikatauluttamaan tulevat
            huollot
          </Text>
          <BasicList>
            <li>Alustat: Web, backend ja mobiili</li>
            <li>
              Palvelut: Suunnittelu, digitaalinen markkinointi ja
              ohjelmistokehitys
            </li>
          </BasicList>
        </div>
      </div>
    </DotSection>
  </Layout>
)
